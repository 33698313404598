// extracted by mini-css-extract-plugin
export var arrow = "styles-module--arrow--283iK";
export var cargo = "styles-module--cargo--3vdvi";
export var equipe = "styles-module--equipe--1Be2H";
export var gestao = "styles-module--gestao--H2j4p";
export var grupo = "styles-module--grupo--2AwzD";
export var icon = "styles-module--icon--eO6eW";
export var label = "styles-module--label--1jBBw";
export var list = "styles-module--list--1suPc";
export var nota = "styles-module--nota--1Rj1R";
export var nome = "styles-module--nome--3fx3o";
export var programa = "styles-module--programa--3J2HQ";
export var title = "styles-module--title--xfRtS";
export var wrapper = "styles-module--wrapper--I0W38";