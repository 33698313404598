// extracted by mini-css-extract-plugin
export var slide = "styles-module--slide--il-sm";
export var button = "styles-module--button--3wtTB";
export var cover = "styles-module--cover--1Xb4p";
export var hero = "styles-module--hero--1DSFW";
export var title = "styles-module--title--3rI1P";
export var logo = "styles-module--logo--iPiO2";
export var image = "styles-module--image--2Zt4B";
export var wrapper = "styles-module--wrapper--17OMP";
export var gradient = "styles-module--gradient--1ETRX";
export var scroll = "styles-module--scroll--3k1cf";
export var content = "styles-module--content--2v1OC";
export var Snudge = "styles-module--Snudge--3p0_b";